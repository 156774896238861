exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-asic-and-fpga-js": () => import("./../../../src/pages/asic-and-fpga.js" /* webpackChunkName: "component---src-pages-asic-and-fpga-js" */),
  "component---src-pages-cloud-systems-js": () => import("./../../../src/pages/cloud-systems.js" /* webpackChunkName: "component---src-pages-cloud-systems-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-development-platforms-js": () => import("./../../../src/pages/development-platforms.js" /* webpackChunkName: "component---src-pages-development-platforms-js" */),
  "component---src-pages-edge-ai-and-software-js": () => import("./../../../src/pages/edge-ai-and-software.js" /* webpackChunkName: "component---src-pages-edge-ai-and-software-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-project-template-js": () => import("./../../../src/templates/project-template.js" /* webpackChunkName: "component---src-templates-project-template-js" */)
}

